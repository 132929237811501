import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import ProductsSlider from "~components/products/productsSlider";
import "../styles/pages/_lp.scss";
import lightBulb from "../images/lp/TSD_Advertising_Hero.png";
import sound1Image from "../images/lp/TSD_Sound_1.png";
import sound2Image from "../images/lp/TSD_Sound_2.png";
import sound3Image from "../images/lp/TSD_Sound_3.png";
import sound1 from "../audio/Audio1.mp3";
import sound2 from "../audio/Audio2.mp3";
import sound3 from "../audio/Audio3.mp3";

var settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "700px",
  slidesToShow: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "500px",
      },
    },
    {
      breakpoint: 2000,
      settings: {
        centerPadding: "300px",
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 821,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
      },
    },
  ],
};
const UnlockDigitalAds = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="Unlock The Potential of Digital Ads"
      description="Digital advertising is an incredible tool to test, learn, scale, and convert – but only if it's done well. See how we do it at The Simple Department."
    />

    <section className="da-lp-hero">
      <img src={lightBulb} />
      <div className="container">
        <div className="content-wrapper">
          <h2>Looking for Sales in all the Wrong Places?</h2>
          <p>
            At its best, digital advertising is an incredible tool to test,
            learn, segment, support and nurture the learning and buying journey
            for your brand/product.
          </p>
          <p>
            At its worst, it is a bottomless sinkhole for your advertising
            budget and an endless series of stats that will leave you dazed and
            confused – stuck in Digital Budget Death Loop.
          </p>
          <p>
            <strong>
              Ready to break-free and unlock the potential that digital can have
              on your business?
            </strong>
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Digital_Ads_Packages" className="lp-btn red-btn">
                Harness Digital’s True Power
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>
    <section className="ms-lp-slider">
      <Slider {...settings} className="symptoms-slider">
        <div className="give-padding">
          <div className="single-slide slide4">
            <div className="slide-content">
              <h3>Meta Debt-a</h3>
              <p>
                Some Meta or Google PPC Guru/Ninja/Wizard told you that they
                knew everything about everything and guaranteed you some insane
                results for next to little money or risk…..but all you are left
                with is a whole bunch of lost time, 0-3 sales and a spreadsheet
                of garbage data.
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide slide5">
            <div className="slide-content">
              <h3>But look at all of the clicks!</h3>
              <p>
                There is data you can use to inform decisions and scale your
                business. Then there is data that is meant to distract you, look
                impressive, but ultimately if those clicks are not translating
                to revenue - what are you even measuring?
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide slide6">
            <div className="slide-content">
              <h3>Upside Down Funnel Cake</h3>
              <p>
                A proper funnel has 3 distinct areas - a wide part, a tighter
                middle and a narrow bottom. Most think they are building a full
                funnel, but they get stuck working on just one section - which
                means you don’t really have a full ad campaign, you just have
                some random results.
              </p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="container narrow-container">
        <h3 className="text-center">
          When you unlock the true power and potential of digital advertising
          for your product/brand, you open up the full world of advertising and
          sponsorship opportunities.
        </h3>
        <p className="text-center spacer-1">
          Digital is just a smart step one. The real fun begins when you layer
          in podcasting, events and sponsorships, TV, Radio, Print, OOH and
          in-store experiences that build upon and support your digital
          marketing infrastructure
        </p>
        <p className="btn-cta-wrapper text-center">
          <strong>
            <Link to="#TSD-Digital_Ads_Packages" className="lp-btn white-btn">
              Tap Here for More Sales/Leads
            </Link>
          </strong>
        </p>
      </div>
    </section>
    <section className="ms-lp-action da-lp-case-studies">
      <div className="container">
        <div>
          <h2>
            How We Have Mastered Digital & Why We Keep Getting Better At It
          </h2>
          <p>
            Here are just a few highlights from some recent case studies we have
            published that showcase how we think through, plan, launch, manage
            and scale your digital advertising operations.
          </p>
        </div>
        <div className="audio-container">
          <div className="single-audio">
            <img src={sound1Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Developing Unique Audience Segments</strong>
                </figcaption>
                <p>
                  Algorithms Do The Hard Work to Find New Members for Food
                  Subscription Service
                </p>
              </div>
              <audio controls src={sound1}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound2Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Targeting Niche Problems</strong>
                </figcaption>
                <p>The Power of PPC in the Skincare Space</p>
              </div>
              <audio controls src={sound2}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound3Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Finding Community</strong>
                </figcaption>
                <p>Social Media Advertising to Connect Farmers to Families</p>
              </div>
              <audio controls src={sound3}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section className="da-lp-brain">
      <div className="container">
        <div className="content-wrapper">
          <h2>
            Actual Insights + <br></br>Endless Creative = <br></br>Ad Success
          </h2>
          <p>
            You need some math brains, some creative brains and some good
            old-fashioned planning brains to really put together successful
            campaigns year after year. We happen to be fully stacked when it
            comes to brain power.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-Digital_Ads_Packages" className="lp-btn blue-btn">
                Ads Now?
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>

    <section className="ms-lp-products" id="TSD-Digital_Ads_Packages">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="text-center">
            Which Superpower Would you Like to Add Today?
          </h2>
        </div>
        <ProductsSlider>
          <div
            title="Small Ads Package"
            price="$1,700"
            extrainfo="per month + ad spend"
            extrainfo2="+ one-time $625 launch fee"
            id="advertising-small"
          >
            <p>
              The Small Ads Package is designed for brands and businesses that
              are looking to dip their toes in the water with paid media in a
              strategic way. This package is excellent for initial audience,
              market or product testing, and can be designed for an ad spend of
              anywhere from $500/month to $1,500/month.
            </p>
          </div>

          <div
            title="Medium Ads Package"
            price="$2,960"
            extrainfo="per month + ad spend"
            extrainfo2="+ one-time $625 launch fee"
            id="advertising-medium"
          >
            <p>
              The Medium Ads Package is created for brands and businesses that
              want to accelerate their growth, find new audiences, launch a new
              brand, product or service and/or re-engage past or potential
              customers. This package can be designed for an ad spend of
              anywhere from $750/month to $2,500/month.
            </p>
          </div>
          <div
            title="Large Ads Package"
            price="$4,080"
            extrainfo="per month + ad spend"
            extrainfo2="+ one-time $625 launch fee"
            id="advertising-large"
          >
            <p>
              The Large Ads Package is tailored for brands and businesses that
              are ready to take the next step in their growth plan, find & grow
              new or undiscovered audiences or enter a new market. This is also
              an ideal package for businesses that have plateaued in recent
              sales and need an injection of fresh ideas, partnerships, &
              strategies to take their brand to new heights. This package can be
              designed for an ad spend of anywhere from $1000/month to
              $3,500/month.
            </p>
          </div>
        </ProductsSlider>
      </div>
    </section>
  </Layout>
);

export default UnlockDigitalAds;
